/**
* WebsiteNI Joints
* WebsiteNI Starter Theme built on JointsWP; http://jointswp.com/.
* Created by WebsiteNI.
*/

/**
* _colours - Small.
*/
/* Colours. */
$white: 			#FFFFFF;
$mediumGrey: 		#CCCCCC;
$grey: 				#3C3C3C;
$lightGrey: 		#F2F2F2;
$black: 			#0A0A0A;

$lightBlue:#d3e1ec;
$mediumBlue:#669fc7;

// $companyColour:		#;

$colorPink: #42abc3;
$colorNavy:#001777;
$colorYellow:#d8b11a;
$colorBlue:#42abc3;

/**
* _colours - Medium.
*/
@media screen and (min-width: 640px) {
    
}

/**
* _colours - Large.
*/
@media screen and (min-width: 1024px) {
    
}

/**
* _colours - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _colours - XXL.
*/
@media screen and (min-width: 1440px) {
    
}