@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style: normal
);

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/calibri/Calibri-Bold.eot');
    src: url('../fonts/calibri/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/calibri/Calibri-Bold.woff2') format('woff2'),
        url('../fonts/calibri/Calibri-Bold.woff') format('woff'),
        url('../fonts/calibri/Calibri-Bold.ttf') format('truetype'),
        url('../fonts/calibri/Calibri-Bold.svg#Calibri-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/calibri/Calibri.eot');
    src: url('../fonts/calibri/Calibri.eot?#iefix') format('embedded-opentype'),
        url('../fonts/calibri/Calibri.woff2') format('woff2'),
        url('../fonts/calibri/Calibri.woff') format('woff'),
        url('../fonts/calibri/Calibri.ttf') format('truetype'),
        url('../fonts/calibri/Calibri.svg#Calibri') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/calibri/Calibri-BoldItalic.eot');
    src: url('../fonts/calibri/Calibri-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/calibri/Calibri-BoldItalic.woff2') format('woff2'),
        url('../fonts/calibri/Calibri-BoldItalic.woff') format('woff'),
        url('../fonts/calibri/Calibri-BoldItalic.ttf') format('truetype'),
        url('../fonts/calibri/Calibri-BoldItalic.svg#Calibri-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/calibri/Calibri-Italic.eot');
    src: url('../fonts/calibri/Calibri-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/calibri/Calibri-Italic.woff2') format('woff2'),
        url('../fonts/calibri/Calibri-Italic.woff') format('woff'),
        url('../fonts/calibri/Calibri-Italic.ttf') format('truetype'),
        url('../fonts/calibri/Calibri-Italic.svg#Calibri-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/calibri/Calibri-Light.eot');
    src: url('../fonts/calibri/Calibri-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/calibri/Calibri-Light.woff2') format('woff2'),
        url('../fonts/calibri/Calibri-Light.woff') format('woff'),
        url('../fonts/calibri/Calibri-Light.ttf') format('truetype'),
        url('../fonts/calibri/Calibri-Light.svg#Calibri-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "slick";
    src: slick-font-url("../fonts/slick.eot");
    src: slick-font-url("../fonts/slick.eot?#iefix") format("embedded-opentype"), slick-font-url("../fonts/slick.woff") format("woff"), slick-font-url("../fonts/slick.ttf") format("truetype"), slick-font-url("../fonts/slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
}
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
