/**
* WebsiteNI Joints
* WebsiteNI Starter Theme built on JointsWP; http://jointswp.com/.
* Created by WebsiteNI.
*/

/**
* _variables - Small.
*/

$paddingSmall: 40px;
$paddingMedium: 75px;
$paddingLarge: 100px;

$marginSmall: 50px;
$marginMedium: 75px;
$marginLarge: 100px;

$transition: 0.3s ease-in-out;

.margin-top-sml {
	margin-top: $marginSmall;
}
.margin-bottom-sml {
	margin-bottom: $marginSmall;
}

/**
* _variables - Medium.
*/
@media screen and (min-width: 640px) {
    
}

/**
* _variables - Large.
*/
@media screen and (min-width: 1024px) {
    
}

/**
* _variables - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _variables - XXL.
*/
@media screen and (min-width: 1440px) {
    
}