//
//  Custom Theme Styles
//  ---------------------------------------------
body{
	font-size: 100%;
	font-size: 16px;
	font-family: 'Calibri',sans-serif; 
}
h1,h2,h3,h4,h5,h6{
	font-family: 'Calibri',sans-serif;
}
.grid-container { max-width: 1300px; } 
.grid-container.full { width: 100%; max-width: 100%; } 

.page-wrapper .panel.wrapper {
	background-color: $colorYellow;
	border-bottom:0px;
	color:#fff;
	text-transform: uppercase;
	
	a,
	strong{
		color:#ffff00;
	}
	p{
		margin-bottom:0px;
	}
	.topcontacts{
		display: flex;
	    align-items: center;
	    justify-content: center;
	}
}
.page-header{
	background: #0060dd; /* Old browsers */
	background: -moz-linear-gradient(left,  #0060dd 18%, #001777 60%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #0060dd 18%,#001777 60%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #0060dd 18%,#001777 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0060dd', endColorstr='#001777',GradientType=1 ); /* IE6-9 */
	color:#fff;
	.logo{
		width: 230px;
	}
	.greet.welcome{
		display: none;
	}
	.besidesearch{
		a{
			color:#fff;
		}
		a.quicktellink:after,
		.switcher-currency:after,
		ul.header.links li:not(.welcome) a:after{
			content:" | ";
			color:#fff;
			display: inline-block;
			margin:0 10px;
			font-size: 17px;
		}
		.switcher-currency:after{
			margin-left: 5px;
		}
		ul.header.links{
			display: inline-block;
			list-style: none;
			padding-left:0;
			margin-left: 0;
			li:not(.welcome){
				padding-left:0px;
				padding-top:0px;
				a{
					font-size:0;
					background:url(../images/account-icon.jpg) 2px 6px no-repeat;
					width: 15px;
					height:24px;
					display: block;
					padding-right: 30px;

					&:after{
						margin-left: 30px;
					}
				}
			}
		}
		.switcher{
			margin-right: 0;
		}
		.switcher .options .action.toggle:after,
		.switcher .options .action.toggle.active:after{
			margin-left: -3px;
		}
		.language-GBP span{
			font-size:0;
			&:before{
				content:" £ ";
				color:#fff;
				font-size:18px;
			}
		}

		.language-EUR span{
			font-size:0;
			&:before{
				content:" € ";
				color:#fff;
				font-size:18px;
			}
		}
		.currency-GBP a,
		.currency-EUR a{
			color: $colorNavy;
		}
	}
}
.block-minicart,
.block-minicart .block-title,
.block-minicart .block-content{
	color:#000;
}
.ambrands-search-wrapper .ambrands-search-input,
.minisearch #search{
    border-radius: 3px;
    border: 0px;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
    height: 40px;
}
.header.content{
	.ambrands-search-wrapper {
	    margin: 10px auto;
	}
}

.topcontacts{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 700;
	font-size: 18px;
	font-size: 1.125em;
	letter-spacing: 1px;

	span{
		margin-right:20px;
	}
}
.page-header .cell.header.panel {
    padding-bottom: 0px;
    padding-top: 0px;
    margin-right:0;

    ul li:after {
	    content: " | ";
	}
	ul li:last-child:after {
	    content: "";
	}
	li.item.link.compare,
	li.greet.welcome {
		display: none;
	}
}
// Hide advanced search link on header 
// title bar on brand slider
// Beforebread block
.form.minisearch .nested,
.amslider-header-container,
.beforebread{
	display: none;
}
#banner-slider-carousel {
	margin-top:0px;
}

.block-static-block.widget, .block-cms-link.widget {
    margin-bottom: 0px;
}
.minicart-wrapper{
	.action.showcart:before {
	    color: #fff;
	}
	.action.showcart .counter.qty {
	    background: $colorPink;
	    color: #fff;
	    height: 20px;
	    line-height: 1;
	    border-radius: 20px;
	    margin: 0px 0 0 -12px;
	    min-width: 20px;
	    overflow: hidden;
	    padding: 0 3px;
	    text-align: center;
	    white-space: normal;
	    font-size: 14px;
	    font-weight: 700;
	    display: inline-flex;
	    justify-content: center;
	    align-items: center;
	}
}
.block-search,
.ambrands-search-wrapper {
    margin-top: 5px;
}
// NAVIGATION
.section-item-title.nav-sections-item-title a{
    color: #000;
}
.nav-toggle:before,
.block-search .label:before{
    color: #fff;
}
div#switcher-currency-trigger-nav,
.section-item-title.nav-sections-item-title.active a{
    color: #000;
}
.nav-sections .header.links a{
	color:#fff;
	letter-spacing: 1px;
	text-transform: lowercase;
	font-weight: 400;
}
.navigation{
	background:transparent;
	font-size:14px;
	font-weight: 700;
	text-transform: uppercase;

	
	.level0 > .level-top,
	.nav-sections .header.links a{
		color:#000;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
	.level0 > .level-top:hover, 
	.level0 > .level-top.ui-state-focus {
	    color: #059bfd;
	    text-decoration: none;
	}
	.level0.active > .level-top, 
	.level0.has-active > .level-top {
	    border-color: #ff5501;
	    border-style: solid;
	    border-width: 0 0 0px;
	    color: #059bfd;
	    text-decoration: none;
	}
	// Child menu item active state
	.level0 .submenu .active > a,
	.level0.active > a:not(.ui-state-active),
	.level0.has-active > a:not(.ui-state-active) {
	    border-color: $colorPink;
	    border-style: solid;
	    border-width: 0 0 0 8px;
	}
	.level0 .submenu,
	.level0 > .level1{
		font-weight: 400;
		letter-spacing: 0px;
		text-transform: capitalize;
		opacity: 1;
	}
	.level0 > .level1 {
	    position: relative !important;
	}
	ul{
		padding:0px;
	}
	a{
		color:#000;
	}
	a:hover {
	    color: #059bfd;
	}
}
// END: NAVIGATION
.carousel-container{
	background-color:#a8ad9f;
    
	.banneroverlaytext{
		background-image:url('../images/homepage-banner.jpg');
		background-repeat:no-repeat;
		background-size:cover;
		min-height:400px;
		width: 100%;
		color: #fff;
		.cell{
			position:relative;
			z-index:10; 
		}
		h1{
	    	color: #fff;
	    	font-size:50px;
	    	font-weight: 700;
	    	margin:0 0 5px;
	    }
	    p{
	    	font-size:22px;
	    	font-size:1.375em;
	 		margin-bottom: 50px;
	    }
	    a{
	    	text-transform: uppercase;
	    	text-decoration: none;
	    	color:#000;
	    	background:#fff;
	    	border-radius:3px;
	    	line-height: 1;
	    	padding:15px;
	    	font-weight:700;
	    	font-size:18px;
	    }
	    a:hover{
			text-decoration: none;
			background:#f2f2f2;
		}
		.slick-slide img{
			display: inline-block;
		}
	}
	.banneroverlaytext .large-6{
		display: flex;
	    align-items: center;
	    justify-content: flex-start;
	}
	.slickhold{
		width: 100%;
		height: 100%;
		display: block;
	}
}
.bannerbox{
	padding:30px 30px 6px;
	text-align: center;
	border-radius:5px;
	background:$colorNavy;
	color:#fff;
	font-size: 16px;

	&.bannerboxlight{
		background:$colorPink;
	}
	a,
	a:hover{
		display: inline-block;
		text-transform: uppercase;
		color: #000000;
		font-size: 18px;
		font-size: 1.125em;
		background:#fff;
		border-radius:3px;
		padding:15px;
		font-weight: 700;
		
	}
	a:hover{
		text-decoration: none;
		background:#f2f2f2;
	}
	strong{
		font-size: 40px;
		font-size: 2.5em;
		line-height: 1;
	}
	p{
		margin-bottom: 24px;
	}
}
#banner-slider-carousel {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
.bluegradback{
	background: #0060dd; /* Old browsers */
	background: -moz-linear-gradient(left,  #0060dd 18%, #001777 60%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #0060dd 18%,#001777 60%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #0060dd 18%,#001777 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0060dd', endColorstr='#001777',GradientType=1 ); /* IE6-9 */
	color:#fff;
}
.partfinder{
	h2{
		font-weight: 700;
		font-size:36px;
		font-size:2.25em;
		line-height: 1;
		margin:0;
	}
	.block-search.widesearch {
	    width: 100%;
	}
	.cell{
		display: flex;
		justify-content:center;
		flex-direction: column;
	}
}
.usplist ul{
	list-style: none;
	padding:0px;
	margin:5px 0px 0px;
	font-size: 13px;
	font-weight: 700;
	color:#8c8c8c;
	display: flex;
	justify-content: space-between;

	li{
		margin-bottom: 0px;
		display: flex;
		align-items:center;
	}
	li:before{
		content:"";
		background:url('../images/tick.svg');
		width: 21px;
		height: 21px;
		display: inline-block;
		margin-right: 29px;
	}
}

h1{
	font-weight: 700;
	font-size: 30px;
	line-height: 1;
	color: #15144d;
}
.relatedtitle{
	font-weight: 700;
	font-size: 25px;
	font-size: 1.5625em;
	line-height: 1;
	color: #15144d;
}
.greyback{
	background: $lightGrey;
}
.lightblueback{
	background: $lightBlue;
}
.mediumblueback{
	background: $mediumBlue;
	color: #fff;
}
.navyback{
	background: $colorNavy;
	color: #fff;
}
.greygrad{
	background: rgb(242,242,242);
	background: linear-gradient(180deg, rgba(242,242,242,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
}
.catbox{
	h3{
		background: #fff;
	    margin-bottom: 1px;
	    text-align: center;
	    text-transform: uppercase;
	    height: 60px;
	    display: flex;
	    align-items: center;
	    justify-content: center;	
	}
	h3{
		position:relative;
		padding:0 30px;
	}
	h3:before, h3:after {
	    border-right: 2px solid;
	    content: '';
	    display: block;
	    height: 8px;
	    margin-top: -6px;
	    position: absolute;
	    -moz-transform: rotate(135deg);
	    -o-transform: rotate(135deg);
	    -webkit-transform: rotate(135deg);
	    transform: rotate(135deg);
	    right: 40px;
	    top: 50%;
	    width: 0;
	}

	h3:after {
	    margin-top: -1px;
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -webkit-transform: rotate(45deg);
	    transform: rotate(45deg);
	}
	p{
		background: #fff;
    	text-align: center;
	}
}




.products-grid li.product-item{
	background:#fff;
	color:#000;
	text-align:center;
	position: relative;
	padding: 0 20px 25px;

	.product-item-name{
		margin:15px auto;
		min-height: 200px;
	}
	.product-item-name a{
		text-transform: capitalize;
		color: #000;
		font-weight: 700;
		font-size: 22px;
		font-size: 1.375em;
		line-height: 1.125;
	}
	.price-box{
		display: none;
		.price-label {
		    display: none!important;
		    line-height: 1;
		}
		.price {
		    font-size: 25px;
		    font-size: 1.5625em;
		    letter-spacing: 1px;
		}
	}
	.product-item-actions .actions-secondary{
		display: none;
	}

	.product-item-inner {
	    position: absolute;
	    bottom: -30px;
	    width: 100%;
	    background: #3d3d3d;
	    color: #fff;
	    left: 0;
	    font-size:16px;
	    text-align: center;
	    padding:3px 10px;
	    line-height: 1.25;
	    min-height: 184px;
	}
}
.cms-home .products-grid li.product-item{
	.product-item-actions .actions-primary{
		position: absolute;
	    bottom: -20px;
	    left: 50%;
	    transform: translateX(-50%);
	}
}
.action.primary,
.pccf.simplex .btn.btn-primary{
    background-image: none;
    background: #669fc7;
    border: 0px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    // font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
    font-weight: 700;
    padding: 14px 40px;
    font-size: 17px;
    font-size: 1.0625em;
    text-transform: uppercase;
    box-sizing: border-box;
    vertical-align: middle;
    transition: all .3s ease-in-out;

    &:hover {
	    background: #46708d;
	    color: #fff;
	    border: 0px;
	    transition: all .3s ease-in-out;
	}

}
// .block.widget{
// 	.products-grid .product-item {
// 	    margin-left: calc((100% - 4 * 24.439%) / 4);
// 	   	width: 24.25%;
// 	    padding: 0 20px 25px;
// 	}
// 	.products-grid .product-item:nth-child(3n + 1) {
// 	    margin-left: 0;
// 	}
// 	.block-title{
// 		display: none;
// 	}
// }

.page-footer {
    background: $colorNavy;
    color:#fff;

    a{
    	color: #fff;
    }
    .footer.content {
	    border-top: 0px;
	    margin-top: 0px;
	    padding-top: 75px;

	    h4{
	    	font-weight: 700;
	    	font-size: 20px;
	    	font-size: 1.25em;
	    	line-height: 1;
	    	margin:0 0 15px;
	    	border-bottom:1px solid  #33436c;
	    	padding-bottom:10px;
	    	position:relative;
	    }
	    ul{
	    	list-style: circle;
	    	padding-left:20px;
	    }
	    li{
	    	margin-bottom: 10px;
	    }
	    .footer-content{
	    	height: 0px;
			overflow: hidden;
			margin-bottom:0px;
			transition: all .3s ease-in-out;
	    }
	    .footer-content.show-content{
			height: auto;
			margin-bottom:25px;
			transition: all .3s ease-in-out;
			padding-top:20px;
		}
	}
}
.footerbottom{
	padding-top:20px;
	padding-bottom:20px;

	p{
		margin-bottom: 0px;
	}
	a{
		color:#000;
	}
	ul{
		margin-left:0px;
		padding-left:0px;
		list-style: none;
	}
	li{
		display: block;
	}
	.cell.small-12.medium-6.text-center-sm.text-left-md {
	    display: flex;
	    align-items: center;
	}
}
.newslettertitle{
	font-weight: 400;
	font-size: 22px;
	font-size: 1.125em;
	margin: 0 auto 40px;
	text-align: center;
}
.introtxt p:first-of-type{
	font-weight: 700;
	font-size: 25px;
	font-size: 1.5625em;
	color: $mediumBlue;
	line-height: 1.4;
	margin-bottom: 32px;
}
.testimonialslide{
	color:#ff81ff;
	font-size:20px;
	font-size: 1.25em;
	font-style: italic;
	font-family: Georgia,Times,Times New Roman,serif; 

	strong{
		font-weight: 400;
		font-size: 15px;
		color: #000000;
		font-style: normal;
		font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
		margin-top: 30px; 
	}
}
.slick-carousel.slick-carousel-home{
    margin-top: 40px;
}

.cms-page-view.page-layout-2columns-right .page-main{
	padding-top:40px;
	padding-bottom:40px;
}

.catwrapper{
	background-color:#f2f2f2;

    .category-image{
    	margin-bottom: 0px;
    	height: 300px;

    	h1{
    		font-weight: 700;
    		font-size: 20px;
    		font-size: 1.25em;
    		line-height: .9;
    		color: #fff;
    		text-transform: lowercase;
    		margin: 0;
    	}
    	.grid-container,
    	.grid-x{
    		height: 100%;
    	}
    	.cell{
    		display: flex;
    		align-items:center;
    	}
	}
	.column.main{
		h1{
			text-transform: capitalize;
			color:#000;
			text-align: center;
			font-size:50px;
			font-size:3.125em;
		}

		.category-description{
			padding-bottom:50px;
			// border-bottom:1px solid #d9d9d9;
			text-align: center;
			width: 630px;
			max-width: 100%;
			margin:0 auto;
		}
		.modes-mode {
		    background: #fff;
		}
	}
	// .breadcrumbs {
	//     position: absolute;
	//     padding: 20px 0 20px 15px;
	//     left: 0;
	//     right: 0;
	//     color:#fff;
	//     font-size: 13px;
	//     a, li{
	//     	color:#fff;
	//     }
	//     li{
	//     	font-size: 13px;
	//     	text-transform: lowercase;
	//     	letter-spacing: 1px;
	//     }
	    
	// }
}
.breadcrumbs .item:not(:last-child):after {
	font-size: 16px;
    line-height: 22px;
    content: " / ";
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    margin:0 10px 0 7px;
}
.page-products .product-item-info,
.relatedrow .product-item-info {
	padding-top:20px;
	padding-bottom:20px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .product-item-actions {
	    margin: 20px auto 30px;
	    display: none;
	}
    .product-item-actions .actions-primary {
	    display: flex;
	    justify-content: center;
	}
	.action.tocart.primary a{
		color:#fff;
		text-decoration: none;
	}
}


// LAYERED NAVIGATION...

.sidebar.sidebar-main{ 
	padding-top:40px;
	padding-bottom:40px;

	.label {
	    display: inline-block;
	    padding: .33333rem .5rem;
	    border-radius: 0;
	    font-size: 14px;
	    line-height: 1;
	    background: transparent;
	    color: #000;
	    white-space: initial;
	    max-width: 128px;
	}
	input.am-input{
		opacity: 0;
	}
	.filter-options .item {
	    position:relative;
	    margin-bottom: 8px;
	}
	a.amshopby-filter-parent:before{
		content: "";
	    display: inline-block;
	    height: 16px;
	    width: 16px;
	    border-radius:3px;
	    border: 1px solid #b7b7b7;  
	    background-color:#fff;
	    position: absolute;
	    top: 5px;
	    left: 0;
	}
	a.amshopby-filter-parent.am_shopby_link_selected:before{
		content:"";
		border: 1px solid #669fc7;
		background-color:#669fc7;
	}
	.filter-options .count {
		font-size: 12px;
    	color: #669fc7;
    	position:absolute;
    	right: 0;
	}
	.block-subtitle.filter-subtitle{
		display: none;
	}
	.block-title.filter-title{
		font-size: 14px;
		letter-spacing: 1px;
		text-transform: lowercase;
	}
	.filter-options-title{
		font-weight: 700;
		font-size:14px;
		margin:15px 0;
		padding-top:25px;
		position:relative;
		border-top:1px solid #e5e5e5;
		text-transform: lowercase;
	}
	.filter-options-title:before, .filter-options-title:after {
	    transition: .1s all ease-in;
	    position: absolute;
	    right: 0;
	    top:35px;
	    width: 8px;
	    height: 1px;
	    background: #006bb4;
	    content: '';
	}
	.filter-options-title:hover {
	    cursor: pointer;
	}
	.filter-options-title:before {
	    right: 5px;
	    transform: rotate(45deg);
	}
	.filter-options-title:after {
	    transform: rotate(-45deg);
	}
	.filter-options-title.filteractive:before {
	    right: 6px;
	    transform: rotate(-45deg);
	}
	.filter-options-title.filteractive:after {
	    transform: rotate(45deg);
	}
	.filter-options-content{
		height: 0px;
		overflow: hidden;
		margin-bottom:0px;
		transition: all .3s ease-in-out;
	}
	.filter-options-content.show-content{
		height: auto;
		margin-bottom:25px;
		transition: all .3s ease-in-out;
		padding-top:20px;
	}
}
.swatch-attribute-options{
	text-align:center;
	margin: 10px auto 10px;
	.swatch-more{
		display: none;
	}
	.swatch-option {
	    padding: 1px 2px;
	    min-width: 20px;
	    max-width: 20px;
	    height: 20px;
	    float: none;
	    margin: 0 5px 5px 0;
	    text-align: center;
	    cursor: pointer;
	    position: relative;
	    border: 1px solid #dadada;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    border-radius: 3px;
	    display: inline-flex;
	}
}
.toolbar-sorter.sorter, .field.limiter{
	label{
		display: inline-block;
		font-size:15px;
		background:transparent;
		color:#000;
	}
}
.pages{
	display: flex;
	.items {
	    display: inline-flex;
	}
	.item {
	    font-size: 16px;
	    letter-spacing: normal;
	    line-height: 1;
	    margin: 0 5px 0 0;
	    border: 1px solid #fff;
	    background:#fff;
	    border-radius: 5px;
	    width: 40px;
	    height: 40px;
	    display: inline-flex;
	    align-items:center;
	    justify-content: center;
	    a, a:visited{
	    	color: #b3b3b3;
	    }
	}
	.item.current{
		border: 1px solid #3d3d3d;
		background-color: #3d3d3d;
		strong.page{
			color:#fff;
			font-size:16px;
		}
	}
	.action {
	    border: 0px;
	    color: #d99ace;
	    display: inline-block;
	    padding: 0;
	    text-decoration: none;
	}
	.action.next {
	    margin-left: 0px;
	}
	.action.previous {
	    margin-right: 0px;
	}
}
.cms-page-view,
.catalog-product-view{
	.beforebread{
		border-bottom:1px solid #e5e7ec;
		background:#fff;
	}
}
.breadcrumbs{
	width:100%;
	max-width: 100%;
	background:#fff;
	margin:0;

	.items{
		width: 1300px;
		max-width: 100%;
		margin:20px auto;
		font-size:16px;
		padding-right: .9375rem;
    	padding-left: .9375rem;
    	display: flex;
	}
	li{
		font-size: 16px!important;
		text-transform: lowercase!important;
		color:#707070!important;
	}
}
.catalog-product-view{
	.page-main{
		width: 100%;
		max-width: 100%;
		background:#f2f2f2;
		padding:30px 0;
	}
	.columns .column.main {
	    width: 1300px;
	    margin:0 auto;
	    padding-bottom:50px;
	    &:after {
		    content: "";
		    visibility: hidden;
		    display: block;
		    height: 0;
		    clear: both;
		}
	}
	.product-info-main{
		.page-title-wrapper .page-title{
			text-transform: capitalize;
			color:#000;
			margin-top: 0px;
		}
		.product-reviews-summary{
			margin:0 0 35px;
			.reviews-actions a.action{
				color:#383838;
			}
		}
		.product-info-stock-sku{ //Hide stock status & SKU
			display: none; 
		}
		.price-box{
			margin: 10px 0 30px;
			.price-label{
				display: none!important;
				line-height: 1;
				text-transform: lowercase;
			}
			.price{
				color:#669fc7;
				font-weight: 700;
				font-size: 4.25rem;
				line-height: 1;
			}
		}
		.product-options-wrapper{
			.field.configurable{
				border-top:1px solid #e5e5e5;
				padding-top:28px;
				padding-bottom:20px;
			}
			.label{
				font-weight: 400;
				background:transparent;
				font-size:15px;
				color: #000;
			}
			.label:after{
				content:": ";
			}
		}
		.swatch-opt{
			border-top:1px solid #e5e5e5;
			border-bottom:1px solid #e5e5e5;
			padding-top:25px;
			padding-bottom:25px;
			.swatch-attribute-label{
				font-weight: 400;
			}
			.swatch-attribute-label:after{
				content:": ";
			}
			.swatch-attribute-options{
				text-align: left;
			}
			.swatch-attribute-options .swatch-option {
				min-width: 25px;
				height: 25px;
			}
		}
		.product-add-form{
		    padding: 1px 20px;
		    background: #fff;
		    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.05);
		    border: 1px solid #e2e2e2;
		    border-radius:5px;
		    // &.quoteEnabled{
		    // 	display: none;
		    // }
		}
		.pc_1emTtntBCmiMjTExm7T8b4PExizMuzTh{
		    padding: 40px 20px;
		    background: #fff;
		    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.05);
		    border: 1px solid #e2e2e2;
		    border-radius:5px;
		    display: none;
		}
		.box-tocart{
			margin: 35px 0;
			.fieldset{
				display: flex;
			}
			.field.qty{
				display: flex;
				align-items:center;
				margin-bottom: 0px;
			}
			.label{
				margin-bottom:0px;
				font-weight: 400;
				font-size: 15px;
				background-color: transparent;
			    color: #000;
			    margin-right: 10px;
			    text-transform: capitalize;
			}
			input[type=number]{
				margin-bottom:0px;
				border-radius:5px;
			}
			.actions{
				padding-top:0px;
			}
			.action.primary {
    			background: $colorPink;
    			padding: 14px 40px;
    		}
		}
		.action.tocompare{
		    background-image: none;
		    background: transparent;
		    border: 0px;
		    color: $mediumBlue;
		    cursor: pointer;
		    display: inline-block;
		    font-family: inherit;
		    font-weight: 700;
		    margin: 0;
		    padding: 0px;
		    font-size: 15px;
		    line-height: 25px;
		    text-transform: lowercase;
		    display: flex;
    		align-items: center;

		    &:after{
		    	content: "";
		    	background:url(../images/compare-icon.jpg) 0 50% no-repeat;
		    	width: 25px;
		    	height: 25px;
		    	display: inline-block;
		    	margin-left: 20px;
		    }
		}
	}
	.quoteEnabled .pc_1emTtntBCmiMjTExm7T8b4PExizMuzTh{
		display: block;
	}
	.quoteEnabled .price-box.price-final_price,
	.quoteEnabled .product-add-form{
		display: none;
	}
	.product.media .gallery-placeholder{
	}
	.product.media .fotorama__stage{
	    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.05);
	    border: 1px solid #e2e2e2;
	    border-radius:10px;
	    max-height: 600px;
	    max-width: 98%;
	    margin:0 auto;
	    overflow: visible;
	    margin-bottom: 20px;
	    background:#fff;
	}
	.fotorama__nav--thumbs {
	    margin-top: 20px;
	}
	.fotorama__thumb {
		box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.05);
	    border: 1px solid #e2e2e2;
	}
	.fotorama__thumb-border {
	    border-width: 2px!important;
	}
	.fotorama__thumb-border{
		border:1px solid #ccc;
	}
	
	.product.attribute.description{
		padding-top:20px;
		padding-bottom:20px;
		width: 1300px;
	    max-width: 100%;
	    margin: 30px auto;
		.value p:first-of-type{
			font-size:30px;
			font-size:1.875em;
			margin-bottom: 30px;
			line-height: 1.125;
		}
	}
}

.relatedrow{
	#block-amshopby-morefrom-heading{
		font-weight: 700;
		font-size: 30px;
		font-size: 1.875em;
		color: $colorNavy;
		display: block;
		margin:0 0 50px;
	}
	.product-item-info {
	    width: 100%;
	    text-align:center;
	}
	.product-item-photo{
		margin:20px auto;
	}
	
	.price-label {
	    display: block!important;
	    line-height: 1;
	}
}


.page-layout-2columns-right{
	.page-wrapper{
		background:#f2f2f2;
	}
	.column.main{
		padding:30px;
		background:#ffffff;
	}
	.sidebar.sidebar-main {
		.buttonbox{
			margin-bottom: 20px;
			display: inline-block;
		}
	}
}
.cms-page-view .column.main,
.cms-home .column.main,
.catalog-product-view .product.attribute.description{
	ul{
		list-style: none;
		margin:20px 0;
		padding-left:0px;
	}
	li{
		background:url(../images/tick.png) 0 3px no-repeat;
		padding-left:40px;
		margin-bottom:25px;
		line-height: 28px;
	}
}
// CHECKOUT
.opc-progress-bar {
    margin: 40px 0;
}
.opc-progress-bar-item._active:before,
.opc-progress-bar-item._active > span:before {
    background: $colorPink;
}
.opc-wrapper{
	.fieldset > .field > .label {
	    font-weight: 400;
	    font-size: 14px;
	}
	.field.street .field .label{
		display: none;
	}
}
// Sign in link in header during checkout
.authentication-wrapper button.action{
	color:#fff;
}
button.action-login {
    background: $colorPink;
    border: 0px;
    color: #fff;
    font-family: inherit;
}
// STUPID IE11 FLEX ISSUE
.page-products, .cms-home{
	.page-wrapper{
		display: block;
	}
}
.ie10 .page-wrapper, .ie11 .page-wrapper{
	height: auto!important;
}
.page-products .products-grid .product-items {
	// display: flex;
	// flex-direction: row;
	// flex-wrap: wrap;
	// justify-content: left;
	 display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  position: relative;
  max-width: 1253px;
  margin: 0 auto;
  width: 100%;
}
.page-products .products-grid,
.amshopby-morefrom,
.block.widget.block-products-list.grid{
	.product-items {
	    margin: 0;
	     @supports (display: grid) {
		    grid-auto-rows: 1fr;
		    display: grid;
		    grid-template-columns: repeat(2, 1fr);
		    grid-column-gap: 20px;
		    grid-row-gap: 20px;
	    }
	}
	.product-item,
	.product-item:nth-child(3n + 1),
	.product-item:nth-child(4n + 1){
		width: 100%;

		@supports (display: grid) {
		    width: 100%!important;
		    margin-bottom: 0;
		    padding:0px;
		}
	}
}
.product.details.product-item-details{
	min-height: 200px;
    display: flex;
    flex-direction: column;
}
.page-products .product-item-info .price-box {
    margin: auto 0 0;
}
.catalog-category-view .block.filter{
	display: none;
}
.product.attribute.overview a{
	display: block;
	font-weight: 700;
	font-size: 18px;
	background: url(../images/tick.png) 0 3px no-repeat;
    padding-left: 40px;
    margin: 25px 0;
    line-height: 28px;
    color:#005ac6;
    transition: all .3s ease-in-out;

    &:hover{
    	text-decoration: none;
    	padding-left:36px;
    	transition: all .3s ease-in-out;
    }
}
.product.description.product-item-description a {
    display: none;
}

.contact-index-index{
	.fieldset > .field:not(.choice) > .label{
		display:none;
	}
	input[type="text"], 
	input[type="tel"],  
	input[type="number"], 
	input[type="email"],
	textarea{
		padding: 8px;
	    height: 40px;
	    border: 0px;
	    background-color: #ebebeb;
	    border-radius:3px;
	}
	textarea{
	    height: auto;
	}
	.column:not(.sidebar-additional) form .actions-toolbar, 
	.column:not(.sidebar-main) form .actions-toolbar, 
	.fieldset .legend, .login-container .fieldset:after{
		margin-left: 0;
	}
} 


.category-egholm .product-item iframe{display:none;}
.product-info-main p.hidepar{display:none;}

.catalog-category-view .description iframe {
    display: none;
}


/**
* Global Styles - Mobile Only.
*/

@media screen and (max-width: 460px) {
	.page-products .products-grid .product-items, 
	.amshopby-morefrom .product-items, 
	.block.widget.block-products-list.grid .product-items {
	    display: block;
	}
	.page-products .products-grid .product-item, 
	.page-products .products-grid .product-item:nth-child(3n + 1), 
	.page-products .products-grid .product-item:nth-child(4n + 1), 
	.amshopby-morefrom .product-item, .amshopby-morefrom .product-item:nth-child(3n + 1), 
	.amshopby-morefrom .product-item:nth-child(4n + 1), 
	.block.widget.block-products-list.grid .product-item, 
	.block.widget.block-products-list.grid .product-item:nth-child(3n + 1), 
	.block.widget.block-products-list.grid .product-item:nth-child(4n + 1){
			margin-bottom: 20px;
	}
}

@media screen and (max-width: 767px) {
	.page-header{
		.besidesearch{
			.quicktellink,
			#switcher-currency,
			ul.header.links{
				display: none;
			}
			.minicart-wrapper {
			    margin-top: 20px;
			}
			.minicart-wrapper{
				position: absolute;
			    top: 5px;
			    right: 17%;
			}
		}
		.nav-toggle {
		    top: 23px;
		}
		.block-search .label:before {
		    padding-top: 5px;
    		margin-right: -8px;
		}
	}
	.page-wrapper .panel.wrapper{
		display: none;
	}
	.navigation{
		.level0 {
			border-top:0px;
		}
		li.level0:last-child {
		    border-bottom: 0px;
		}
		.level0:before,
		li.level0:last-child:after {
		    content: "";
		    width: 100%;
		    height: 1px;
		    display: block;
		    background: #fff;
		    opacity: 0.5;
		}
	}
	.carousel-container .banneroverlaytext h1 {
	    font-size: 28px;
	}
	.banneroverlaytext{
		text-align: center;
	}
	.catbox h3:before, .catbox h3:after{
		content:none;
	}
	.brandlogo{
		margin: 10px auto;
	}
	.page-with-filter .columns .sidebar-main {
	    order: 2;
	}
	.breadcrumbs{
		display: none;
	}
	.category-image{
		background-size:0;
		height: 0;
		display: none;
	}
	.page-header {
	    border-bottom: 0px;
	    margin-bottom: 0px;
	}
	.catalog-category-view #maincontent{
		padding-top:30px;
		padding-bottom:30px;
		background-color:#f2f2f2;
	}
	.product-image-container{
		max-width: 150px;
	}
	.footer.content h4:before, .footer.content h4:after {
	    transition: .1s all ease-in;
	    position: absolute;
	    right: 20px;
	    top:8px;
	    width: 8px;
	    height: 1px;
	    background: #fff;
	    content: '';
	}
	.footer.content h4:hover {
	    cursor: pointer;
	}
	.footer.content h4:before {
	    right: 25px;
	    transform: rotate(45deg);
	}
	.footer.content h4:after {
	    transform: rotate(-45deg);
	}
	.footer.content h4.filteractive:before {
	    right: 26px;
	    transform: rotate(-45deg);
	}
	.footer.content h4.filteractive:after {
	    transform: rotate(45deg);
	}
	.catalog-product-view .product.attribute.description .value p:first-of-type{
		font-size: 20px;
	}
	.product.data.items > .item.title > .switch{
		line-height: 30px;
	}
	.product-info-main {
	    padding: 20px;
	}
	.pccf.simplex .col-md-2, .pccf.simplex .col-md-3, 
	.pccf.simplex .col-md-4, .pccf.simplex .col-md-5, 
	.pccf.simplex .col-md-6, .pccf.simplex .col-lg-6, 
	.pccf.simplex .col-md-8, .pccf.simplex .col-lg-8 {
	    padding-left: 0px!important;
	    padding-right: 0px!important;
	}
	.catalog-product-view .columns .column.main {
	    order: 0;
	}
}
/**
* Global Styles - Medium.
*/
@media screen and (min-width: 640px) {
	.nav-sections {
		margin-bottom: 0px;
	}
	
	.page-layout-1column .product.media{
		width: 54%;
	}
}
/**
* Global Styles - iPad.
*/
@media screen and (min-width: 768px) {
	.page-header{
		.logo{
			width: 250px;
		}
		.nav-sections-item-content .switcher{
		    display: none !important;
		}
	}
	.besidesearch{
		float: right;
		padding-top:28px;
		margin-right:10px;
		.minicart-wrapper .action.showcart:before {
		    margin-top: -22px;
		}
		.quicktellink{
			display: none;
		}
	}
	.navhold{
		float: right;
		clear: both;
		width: 100%;
		border-top:1px solid #6184bd;
		.nav-sections{
			background:transparent;
		}
	}
	.navigation .level0 > .level-top a.mobonly{
		display: none;
	}
	.block-search .action.search{
		padding: 5px 10px;
	    right: 0px;
	    border-radius: 0 3px 3px 0;
	    background:$colorBlue;
	}
	.page-layout-2columns-left{
		.column.main{
			width: 75%;
		}
		.sidebar.sidebar-main {
    		width: 25%;
    	}
	}
	.page-layout-2columns-right{
		.column.main{
			border-radius:10px;
			width: 75%;
			padding:40px;
		}
		.sidebar.sidebar-main {
    		width: 23%;
    		padding-top:0;
    		.buttonbox{
				display: block;
			}
    	}
	}
	.header.content{
		.minicart-wrapper,
		.block-search,
		.ambrands-search-wrapper {
		    margin-top: 20px;
		}
	}
	.block-search .action.search:before {
	    line-height: 30px;
	    color: #fff;
	}
	.action.search.disabled, 
	.block-search .action.search[disabled], 
	fieldset[disabled] .block-search .action.search {
	    opacity: 1;
	}

	.navigation{
		.level0 > .level-top,
		.nav-sections .header.links a,
		a{
			color:#fff;
		}
		.level0:before {
		    content: none;
		}
		.level0 > .level-top{
			padding: 0px 8px;
		}
		ul {
		    display: flex;
		    justify-content: space-between;
		    max-width:96%
		}
		.level0 .submenu .active > a{
	    	border-width: 0 0 0 3px;
		}
		.level0.active > a:not(.ui-state-active),
		.level0.has-active > a:not(.ui-state-active) {
		    border-width: 0 0 0 0px;
		}
	}
	.catwrapper{
		.column.main{
			padding:40px 20px;
		}
	}
	.footerbottom{
		li{
			display: inline-block;
			&:after{
				content:"|";
				margin:0 20px;
			}
			&:last-child:after{
				content:"";
				margin:0;
			}
		}
	}
	.page-footer .footer.content{
		.footer-content {
			height: auto;
		}
		h4{
	    	border-bottom:0px;
	    	padding-bottom:0px;
	    	margin:0 0 35px;
	    }
	}
	// FIXES ROW HEIGHT FOR PRODUCT LISTINGS
	.page-products .products-grid,
	.amshopby-morefrom,
	.page-products.page-layout-1column .products-grid,
	.block.widget.block-products-list.grid,
	.amshopby-morefrom{
		.product-items {
			.product-item,
			.product-item:nth-child(3n + 1),
			.product-item:nth-child(4n + 1){
			    margin: 2%;
	    		width: 46%;
	    	}
		}
	}

}
/**
* Global Styles - Large.
*/
@media screen and (min-width: 1024px) {
	.beforebread{
		display: block;
	}
	
	.besidesearch{
		float: right;
		padding-top:22px;
		margin-right:10px;

		.quicktellink{
			display: inline-block;
		}
	}
	.page-header{
		.logo{
			width: 300px;
		}
	}
	.navhold{
		width: 65%;
		padding-top:5px;
		margin-top: -30px;
		border-top:1px solid #334a99;
	}
	// Parent menu arrow...
	.navigation{
		font-size:13px;
		letter-spacing: 1px;

		.category-item.level-top.parent{
			position:relative;
			padding-right:10px;
		}
		.category-item.level-top.parent{
			&:before, &:after {
			    transition: .1s all ease-in;
			    position: absolute;
			    right: 0;
			    top:26px;
			    width: 8px;
			    height: 2px;
			    background: #fff;
			    content: '';
			}
			&:before {
			    right: 5px;
			    transform: rotate(45deg);
			}
			&:after {
			    transform: rotate(-45deg);
			}
			&:hover:before,
			&:hover:after,
			&.has-active:before,
			&.has-active:after{
				background: $colorPink;
			}
		}
		.level0 > .level-top {
		    padding: 0px 12px;
		}
	}
	// Parent menu END
	.carousel-container{
		position:relative;
		min-height: 700px;
		background-image:url('../images/homepage-banner.jpg');
		background-repeat:no-repeat;
		background-size:cover;
		display: flex;
	    align-items: center;
	    justify-content: flex-start;
	    .banneroverlaytext{
	    	background-image:none;
	    }
		.banneroverlay{
			position:absolute;
			top: 32px;
			left: 0px;
			z-index: 1;
		}
	}
	.grid-margin-x>.large-125 {
	    width: calc(19% - 1.875rem);
	}

	.paymentlogos{
		margin-left: 50px;
	}

	.page-products .products-grid .product-item,
	.page-products .products-grid .product-item:nth-child(4n + 1),
	.block.widget.block-products-list.grid .product-item {
	   margin-left: calc((100% - 4 * 24.439%) / 4);
	   padding: 0;
	   // width: 32.333333%;
	}
	.page-products .products-grid .product-item:nth-child(4n + 1) {
    	margin-left: 0;
	}
	// FIXES ROW HEIGHT FOR PRODUCT LISTINGS
	.page-products .products-grid,
	.amshopby-morefrom,
	.page-products.page-layout-1column .products-grid,
	.block.widget.block-products-list.grid{
		.product-items {
		    @supports (display: grid) {
		    	margin: 0;
			    grid-template-columns: repeat(4, 1fr);
		    }
			.product-item,
			.product-item:nth-child(3n + 1),
			.product-item:nth-child(4n + 1){
	    		width: 23%;
			    margin: 1%;			
				// @supports (display: grid) {
				//     width: 100%;
				//     margin-bottom: 0;
				// }
			}
		}
	}
	.amshopby-morefrom{
		.product-items {
		    @supports (display: grid) {
		    	margin: 0;
			    grid-template-columns: repeat(4, 1fr);
		    }
		}
	}
	.catwrapper{
	    .category-image{
	    	margin-bottom: 0px;
	    	height: 300px;

	    	h1{
	    		font-weight: 700;
	    		font-size: 90px;
	    		font-size: 5.625em;
	    	}
	    	.grid-container,
	    	.grid-x{
	    		height: 100%;
	    	}
	    	.cell{
	    		display: flex;
	    		align-items:center;
	    	}
		}
		.column.main{
			padding:40px;
		}
	}
	.sidebar.sidebar-main{ 
		.label {
		    font-size: 15px;
		}
		
		.filter-options .count {
			font-size: 15px;
		}
		
		.block-title.filter-title{
			font-size: 17px;
		}
		.filter-options-title{
			font-size:17px;
		}
	}
	.page-layout-2columns-left{
		.column.main{
			width: 80%;
		}
		.sidebar.sidebar-main {
    		width: 20%;
    	}
	}
	.halfcovered{
		position:relative;
		min-height: 700px;
		&:before{
			content:"";
			width:50vw;
			height: 100%;
			position:absolute;
			top: 0;
			left: 0;
			background-image:url(../images/kew-homepage-back.jpg);
			background-repeat:no-repeat;
			background-size:cover;
			background-position:0 0;
		}
	}
	.catalog-product-view{
		.product.data.items > .item.title{
		    width: 300px;
		}
		.product.data.items #tab-label-description{ 
			margin-left: calc(50% - 300px); 
		}
		.product.data.items > .item.content {
		    margin-top: 59px;
		}
		.product.data.items > .item.title > .switch {
		    font-weight: 400;
		    line-height: 25px;
		    font-size: 1.5em;
		    color: #fff;
		    text-decoration: none;
		    background: #a9a9a9;
		    border: 0px;
		    height: 60px;
		    padding: 5px 20px 5px 20px;
		    width: 100%;
		    line-height: 50px;
		    text-align: center;
		    vertical-align: middle;
		}
		.product.data.items > .item.title.active > .switch{
			color: #000000;
			background:#fff;
		}
		.product.data.items > .item.title:not(.disabled) > .switch:hover{
			color: #444;
			background:#fcfcfc;
		}
		.product.attribute.description{
			.value{
				columns: 2 400px;
				column-gap: 10em;
				column-rule: 1px solid #ccc;
			}
			.value ul{
				-webkit-column-break-inside: avoid; /* Chrome, Safari */
				page-break-inside: avoid;           /* Theoretically FF 20+ */
				break-inside: avoid-column;         /* IE 11 */
				display:table;                      /* Actually FF 20+ */
			}
		}
		.review-form {
		    margin: 30px auto;
		}
	}
	
    .grid-margin-x>.medium-3.brandlogo {
        width: calc(16% - 1.875rem);
    }
    
	
}
/**
* Global Styles - XL.
*/
@media screen and (min-width: 1200px) {
	.navigation{
		font-size:17px;
	}
	.page-header{
		.logo{
			width: 366px;
		}
		.navhold{
			margin-top: -50px;
		}
	}
	.page-products .products-grid,
	.block.widget.block-products-list.grid{
		.product-items {
		    grid-column-gap: 30px;
		    grid-row-gap: 30px;
		}
	}
}
/**
* Global Styles - XXL.
*/
@media screen and (min-width: 1440px) {
	.navigation{
		font-size:18px;
		ul {
		    max-width:100%
		}
	}
}